export const variants00 = {
   hidden: { opacity: 0, y: -10 },
   show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "spring", duration: 2, delay: 0.1 },
   },
};

export const variants01 = {
   hidden: { x: 0, opacity: 0, y: 10 },
   show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "tween", duration: 2, delay: 0.5 },
   },
   exit: {
      opacity: 0,
   },
};

export const variants02 = {
   hidden: { x: 0, opacity: 0, y: 15 },
   show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "tween", duration: 2, delay: 0.7 },
   },
   exit: {
      opacity: 0,
   },
};

export const variants03 = {
   hidden: { x: 0, opacity: 0, y: 3 },
   show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "tween", duration: 1, delay: 0 },
   },
   exit: {
      opacity: 0,
   },
};
