import router from "next/router";
import Slider, { Settings } from "react-slick";
import { BlogCard } from "components/blog-card";
import { Button } from "components/button";
import { MainContainer, NonBannerContainer } from "styles/index/index.style";
import { NewTextHeadingOne } from "styles/text-heading-one";
import { Div } from "styles/typography";
import { SchoolCards, BlogPostContainer } from "./landing-page.style";

export const Articles = ({ blogData, header, isSeeMore }) => {
   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 4,
      speed: 1000,
      autoplaySpeed: 4000,
      initialSlide: 0,
      infinite: true,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
               centerMode: true,
               centerPadding: "40",
               initialSlide: 3,
            },
         },
         {
            breakpoint: 1500,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               arrows: true,
            },
         },
      ],
   };

   return (
      <NonBannerContainer>
         <MainContainer>
            <BlogPostContainer>
               <Div display="flex" alignItems="center" justifyContent="space-between">
                  <NewTextHeadingOne>{header}</NewTextHeadingOne>
                  <Button
                     variant="new_outline"
                     size="small"
                     onClick={() =>
                        router.push({
                           pathname: "/blog",
                        })
                     }
                  >
                     {isSeeMore && "See More"}
                  </Button>
               </Div>
               <SchoolCards>
                  <Slider draggable {...settings} className="card-slider">
                     {blogData?.map(({ imageUrl, summary, title, slug, categories }, index) => (
                        <BlogCard
                           key={`${title}-${index}`}
                           imgSrc={imageUrl}
                           header={title}
                           body={summary}
                           category={categories}
                           isBtn
                           slug={slug}
                        />
                     ))}
                  </Slider>
               </SchoolCards>
            </BlogPostContainer>
         </MainContainer>
      </NonBannerContainer>
   );
};
