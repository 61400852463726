import { IAddAdvert } from "pages/admin/adverts/add";
import { Http, API } from "services/http/http";
import { paramsObjectToQueryString } from "utils/generic-utils";

export const fetchAdverts = (payload: Record<string, any>) =>
   API({
      method: "GET",
      url: `/cms/advert/${paramsObjectToQueryString(payload)}`,
   });
export const fetchAllAdverts = () => Http.get(`/cms/advert?page=1&limit=none`);
export const fetchAllRankedAds = () => Http.get(`/cms/advert/ranked`);
export const createAdvert = (payload: IAddAdvert) => Http.post("/cms/advert", payload);
export const updateAdvert = (id: string, payload: IAddAdvert) =>
   Http.put(`/cms/advert/${id}`, payload);
export const deleteAdvert = (id: string) => Http.delete(`/cms/advert/${id}`);
export const fetchSingleAdvert = (token, { queryKey }) =>
   Http.get(`/cms/advert/${queryKey[1]}`, {
      headers: {
         authorization: token,
      },
   });

export const searchAdverts = (val: string) => Http.get(`/cms/advert?search=${val}`);

export const homePageAdverts = () => Http.get("/cms/advert/home/ranked");

export const searchPageAdverts = () => Http.get("/cms/advert/search/ranked");

export const blogPageAdverts = () => Http.get("/cms/advert/blog/ranked");

export const scholarshipPageAdverts = () => Http.get("/cms/advert/scholarship/ranked");

export const edcommercePageAdverts = () => Http.get("/cms/advert/edcommerce/ranked");
