/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */
import { useRef } from "react";
// import { FaUserCircle } from "react-icons/fa";
import Slider, { Settings } from "react-slick";
import {
   TestimonialCardContainer,
   TestimonialDivider,
   UpdatedTestimonialContainer,
} from "./landing-page.style";

export const UpdatedTestimonialCards = ({ testimonials }) => {
   const slider: any = useRef(null);
   // const [currentSlide, setCurrentSlide]: any = useState(0);

   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 3,
      speed: 1000,
      autoplaySpeed: 4000,
      arrows: false,
      initialSlide: 0,
      infinite: true,
      // afterChange: (indexOfCurrentSlide) => {
      //    setCurrentSlide(indexOfCurrentSlide);
      // },
      responsive: [
         {
            breakpoint: 2000,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               arrows: false,
               centerMode: true,
               centerPadding: "40",
               initialSlide: 3,
            },
         },
         {
            breakpoint: 1500,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
            },
         },
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
            },
         },
         {
            breakpoint: 500 || 400 || 300,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   return (
      <UpdatedTestimonialContainer>
         <TestimonialDivider>
            <div className="divider-one">
               <h3>Our parents say...</h3>
               <div className="slider-buttons">
                  <div className="prev-button" onClick={() => slider?.current?.slickPrev()}>
                     <img src="/landing-page/arrow-back.png" alt="arrow" />
                  </div>
                  <div className="next-button" onClick={() => slider?.current?.slickNext()}>
                     <img src="/landing-page/arrow-forward.png" alt="arrow" />
                  </div>
               </div>
            </div>
            <div className="divider-two">
               <Slider ref={slider} draggable accessibility {...settings}>
                  {[
                     testimonials?.map((t) => (
                        <UpdatedTestimonialCard key={t} text={t.content} name={t.name} />
                     )),
                  ]}
               </Slider>
            </div>
         </TestimonialDivider>
      </UpdatedTestimonialContainer>
   );
};

export const UpdatedTestimonialCard = ({ text, name }: { text: string; name: string }) => {
   return (
      <TestimonialCardContainer>
         <img className="quotes" src="/landing-page/quotes.png" alt="quotes" />
         {/* <FaUserCircle size={30} /> */}
         <p>{text}</p>
         <h4 className="user-name">{name}</h4>
      </TestimonialCardContainer>
   );
};
